import React from 'react';
import { ReactComponent as Linkedin } from '../../../Assets/images/icons/linkedin.svg';

const MedicalAdvisoryBoardAr = () => {
    return (
        <section dir='rtl' className='aug-leadership-team'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-11 col-12'>
                        <h2 className="aug-leadership-team__title"> المجلس الاستشاري السريري </h2>
                        <div className='row gx-80'>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/medical-advisory-board/vibha-jain.webp')} alt="Vibha Jain, Member of Clinician Advisory Council at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>د. فيبا جين</h3>
                                    <a href='https://www.linkedin.com/in/dr-vibha-jain-health-wellness-it/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/medical-advisory-board/arif-khan.webp')} alt="Dr. Arif Khan, Member of Clinician Advisory Council at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>د. عارف خان</h3>
                                    <a href='https://www.linkedin.com/in/dr-arif-khan-850533a/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/medical-advisory-board/ramy-khalil.webp')} alt="Ramy Khalil MD, Member of Clinician Advisory Council at Augnito" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>د. رامي خليل، دكتور في الطب </h3>
                                    <a href='https://www.linkedin.com/in/ramy-khalil-md/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MedicalAdvisoryBoardAr;