import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const CareersMetaAr = () => {

    return (
        <Helmet>
            <title>الوظائف في Augnito | تعزيز وتمكين الأطباء</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="كن جزءًا من فريق Augnito المبتكر، الذي يطور حلول الذكاء الاصطناعي والتعرف على الكلام للرعاية الصحية العربية. استكشف الفرص لإحداث ثورة في التكنولوجيا الطبية في الخليج." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar" />
            <link rel="canonical" href={Constants.SITE_URL + "/ar/careers"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/careers"} />
            <meta property="og:title" content="الوظائف في Augnito | تعزيز وتمكين الأطباء" />
            <meta property="og:description" content="كن جزءًا من فريق Augnito المبتكر، الذي يطور حلول الذكاء الاصطناعي والتعرف على الكلام للرعاية الصحية العربية. استكشف الفرص لإحداث ثورة في التكنولوجيا الطبية في الخليج." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/careers"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="الوظائف في Augnito | تعزيز وتمكين الأطباء" />
            <meta name="twitter:description" content="كن جزءًا من فريق Augnito المبتكر، الذي يطور حلول الذكاء الاصطناعي والتعرف على الكلام للرعاية الصحية العربية. استكشف الفرص لإحداث ثورة في التكنولوجيا الطبية في الخليج." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us"  />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/careers"}   />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default CareersMetaAr;
