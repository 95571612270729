import React from 'react';
import './SecureDesign.scss';

const SecureDesignAr = () => {

    return (
       <section dir='rtl' className='aug-secure-design aug-secure-design-ar'>
            <div className='container'>
                <div className='aug-secure-design-wrap border-top'>
                    <div className='row gx-80'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-secure-design-box'>
                                <h2 className='mb-3'>آمن حسب التصميم </h2>
                                <p className='mb-0'>في Augnito، يتم تضمين أمان البيانات في عملية تطوير منتجاتنا. بُنيت هياكل الحوكمة لدينا على مدى عقدين من الخبرة في مجال الرعاية الصحية. نحن ملتزمون بحماية المعلومات من خلال تقنيات التشفير المعتمدة في الصناعة، بالإضافة إلى مراجعات صارمة لميزات الأمان داخل أنظمتنا وسياساتنا. </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-secure-design__img'>
                                <ul className='list-unstyled mb-0'>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/iso-27001.webp')} className="img-fluid" height={80} width={80} alt="iso27001 logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/aicpa.webp')} className="img-fluid" height={80} width={80} alt="aicpa soc2 logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/gdpr.webp')} className="img-fluid" height={80} width={80} alt="gdpr logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/hipaa.webp')} className="img-fluid" height={80} width={132} alt="hipaa logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/doh.webp')} className="img-fluid" height={80} width={132} alt="doh logo"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default SecureDesignAr;