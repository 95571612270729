import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants';

const OmniMetaAr = () => {

    return (
        <Helmet>
            <title>Ambient AI Scribe | Augnito Omni لتحسين التوثيق السريري</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="تتمكن تقنية الذكاء الاصطناعي المتقدمة التي يقدمها Augnito Omni من تحويل المحادثات بين الطبيب والمريض في الخليج إلى سجلات طبية دقيقة بسلاسة، والتكيف مع اللهجات الإقليمية والتكامل مع أنظمة السجلات الطبية الإلكترونية المحلية. يساعد على تخفيف الإرهاق الذي يعاني منه الأطباء ويعزز رعاية المرضى في مؤسسات الرعاية الصحية العربية." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar" />
            <link rel="canonical" href={Constants.SITE_URL + "/ar/omni"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/omni"} />
            <meta property="og:title" content="Ambient AI Scribe | Augnito Omni لتحسين التوثيق السريري" />
            <meta property="og:description" content="تتمكن تقنية الذكاء الاصطناعي المتقدمة التي يقدمها Augnito Omni من تحويل المحادثات بين الطبيب والمريض في الخليج إلى سجلات طبية دقيقة بسلاسة، والتكيف مع اللهجات الإقليمية والتكامل مع أنظمة السجلات الطبية الإلكترونية المحلية. يساعد على تخفيف الإرهاق الذي يعاني منه الأطباء ويعزز رعاية المرضى في مؤسسات الرعاية الصحية العربية." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" /> 
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/omni"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Ambient AI Scribe | Augnito Omni لتحسين التوثيق السريري" />
            <meta name="twitter:description" content="تتمكن تقنية الذكاء الاصطناعي المتقدمة التي يقدمها Augnito Omni من تحويل المحادثات بين الطبيب والمريض في الخليج إلى سجلات طبية دقيقة بسلاسة، والتكيف مع اللهجات الإقليمية والتكامل مع أنظمة السجلات الطبية الإلكترونية المحلية. يساعد على تخفيف الإرهاق الذي يعاني منه الأطباء ويعزز رعاية المرضى في مؤسسات الرعاية الصحية العربية." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/omni"} />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default OmniMetaAr;