import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants';

const SpectraMetaAr = () => {

    return (
        <Helmet>
            <title>Augnito Spectra | التعرف البديهي على الكلام والبرمجة اللغوية العصبية مع إمكانيات القيادة والتحكم</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="تكنولوجيا تحويل الصوت إلى نص طبي رائدة بدقة لا مثيل لها. إملاء طبي آمن قائم على السحابة ومتوافق مع لوائح الصحة الخليجية. يتكامل بسلاسة مع أنظمة السجلات الطبية الإلكترونية الرائدة. إمكانية الوصول من أي جهاز في كافة أنحاء شبه الجزيرة العربية." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar" />
            <link rel="canonical" href={Constants.SITE_URL + "/ar/spectra"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/spectra"} />
            <meta property="og:title" content="Augnito Spectra | التعرف البديهي على الكلام والبرمجة اللغوية العصبية مع إمكانيات القيادة والتحكم" />
            <meta property="og:description" content="تكنولوجيا تحويل الصوت إلى نص طبي رائدة بدقة لا مثيل لها. إملاء طبي آمن قائم على السحابة ومتوافق مع لوائح الصحة الخليجية. يتكامل بسلاسة مع أنظمة السجلات الطبية الإلكترونية الرائدة. إمكانية الوصول من أي جهاز في كافة أنحاء شبه الجزيرة العربية." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" /> 
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/spectra"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Augnito Spectra | التعرف البديهي على الكلام والبرمجة اللغوية العصبية مع إمكانيات القيادة والتحكم" />
            <meta name="twitter:description" content="تكنولوجيا تحويل الصوت إلى نص طبي رائدة بدقة لا مثيل لها. إملاء طبي آمن قائم على السحابة ومتوافق مع لوائح الصحة الخليجية. يتكامل بسلاسة مع أنظمة السجلات الطبية الإلكترونية الرائدة. إمكانية الوصول من أي جهاز في كافة أنحاء شبه الجزيرة العربية." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/spectra"} />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default SpectraMetaAr;